import { render, staticRenderFns } from "./place-add-edit.vue?vue&type=template&id=27242aad"
import script from "./place-add-edit.vue?vue&type=script&lang=js"
export * from "./place-add-edit.vue?vue&type=script&lang=js"
import style0 from "./place-add-edit.vue?vue&type=style&index=0&id=27242aad&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports