<template>
  <div class="sendLocation">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contSendDio">
        <send-loca-map v-if="isDio" :dioType="dioType" :lookData="inform" @backLocation="backLocation"></send-loca-map>
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm()" size="small" v-if="dioType=='send'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import sendLocaMap from './sendLocaMap'
import { wgs84togcj02,gcj02towgs84} from '@/utils/positionUtil';//百度坐标与gps坐标转换方法
export default {
  data() {
    return {
      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      typeNum:'',//为任务选择地址时选择存放的地方用的

      inform:{
        // point:{
        //   lng:'',
        //   lat:'',
        // },
        // address:''
      },
    }
  },
  components:{
    sendLocaMap,
  },
  created(){
    
  },
  methods:{
    //初始化
    init(type,item){
      this.dioWidth = '600px'
      
      this.dioType = type
      this.isDio = true
      if(type=='send'){
        this.dioTit = '定位'
        // this.typeNum = item ? item : ''
        if(item){
          let wgs84togcj02Val = wgs84togcj02(item.lng,item.lat);//gps转火星坐标
          this.inform = {
            point:{
              lng:wgs84togcj02Val[0],
              lat:wgs84togcj02Val[1],
            },
            address:item.address
          }
        }
      }else if(type=='look'){
        this.dioTit = '查看位置'
        let wgs84togcj02Val = wgs84togcj02(item.location.lng,item.location.lat);//gps转火星坐标
        this.inform = {
          point:{
            lng:wgs84togcj02Val[0],
            lat:wgs84togcj02Val[1],
          },
          address:item.location.address
        }
      }
    },
    //地图组件返回
    backLocation(val){
      // console.log('地图组件返回val',val);
      this.inform = val
    },
    //提交检查
    checkForm(){
      //判断对象是否为空
      if(Object.keys(this.inform).length == 0){
        return this.$message.warning('请选择定位点')
      }
      // let gcj02towgs84Val = gcj02towgs84(this.inform.point.lng,this.inform.point.lat)
      // let dataObj = {
      //   lng:gcj02towgs84Val[0],
      //   lat:gcj02towgs84Val[1],
      //   address:this.inform.address
      // }
      // let gcj02towgs84Val = gcj02towgs84(this.inform.point.lng,this.inform.point.lat)
      let dataObj = {
        lng:this.inform.point.lng,
        lat:this.inform.point.lat,
        address:this.inform.address
      }
      if(this.typeNum) dataObj.type = this.typeNum
      this.$emit('backLocation',dataObj)
      this.closeDio()
    },
    //关闭弹窗
    closeDio (){
      this.inform = {}
      this.typeNum = ''
      this.isDio = false
    },
  },
}
</script>
<style lang="scss">
.sendLocation{
  .contSendDio{
    height: 500px;
  }
}
</style>