<template>
	<div class="sendLocaMap">
		<div id="map1"></div>
		<div class="searchDiv" v-if="dioType=='send'">
			<el-input type="text" v-model="inform.address" placeholder="输入地址搜索" size="small" @input="addressInput"></el-input>
		</div>
		<div class="addressDiv" v-if="addressList.length>0">
			<el-radio-group v-model="inform.currRadio" @change="radioChange">
				<el-radio :label="item.id" v-for="(item,index) in addressList" :key="index" v-if="item.id">
					<div class="li">
						<div class="name" :title="item.name">{{item.name}}</div>
						<p class="tips" :title="item.address">{{item.address}}</p>
					</div>
				</el-radio>
			</el-radio-group>
		</div>
	</div>
</template>

<script>
import {wgs84togcj02,gcj02towgs84} from '@/utils/positionUtil';//高德坐标与gps坐标转换方法
// import loadBMapSend from '@/assets/js/loadBMap'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
export default {
	props:['dioType','lookData'],
  data() {
    return {
			sendmap:null,
			sendmapInfo:{
				lng:116.397428,
				lat:39.90923,
			},
			
			addressList:[],//地址列表
			send_marker:null,
			inform:{
				address:'',
				currRadio:''
			}
		}
	},
	components:{
		
	},
	async created(){
		this.sysParamGetMap()
	},
	mounted(){
		
	},

	watch:{
		
	},
	methods:{
		//获取系统参数
		async sysParamGetMap () {
			let dataObj = {
				'paramDomain': 'PARAM_DOMAIN_MAP'
			}
			const res = await apiUrl.sysParamGetMap(qs.stringify(dataObj))
			if(res?.code==200){
				let wgs84togcj02Val = wgs84togcj02(res.data.PARAM_CODE_MAP_CENTRE_LNG,res.data.PARAM_CODE_MAP_CENTRE_LAT);//gps转火星坐标
				this.sendmapInfo={
					lng:parseFloat(wgs84togcj02Val[0]),//中心经度
					lat:parseFloat(wgs84togcj02Val[1])//中心纬度
				}
				this.sendMapInit()

			}else{
				this.$message.error(res.message)
			}
		},
		//地图初始化
		sendMapInit (){
			let that = this
			this.sendmap = new AMap.Map('map1', {
				rotateEnable: true,//是否开启地图旋转交互 鼠标右键 + 鼠标画圈移动 或 键盘Ctrl + 鼠标画圈移动
				pitchEnable: true, // 是否开启地图倾斜交互 鼠标右键 + 鼠标上下移动或键盘Ctrl + 鼠标上下移动
				zoom: 13.8, //初始化地图层级
				pitch: 43.8, // 地图俯仰角度，有效范围 0 度- 83 度
				rotation: 0,//初始地图顺时针旋转的角度
				viewMode: '3D', //开启3D视图,默认为关闭
				zooms: [2, 20],//地图显示的缩放级别范围
				center: [this.sendmapInfo.lng,this.sendmapInfo.lat],//初始地图中心经纬度
				// terrain: true, // 开启地形图
				resizeEnable:true,
			});
			this.sendmap.on('click', (e) => {
				// console.log('当前点击经纬度：',e.lnglat.lng,e.lnglat.lat);
				that.inform.currRadio
				that.addMarkerSend(e.lnglat)
			});
			AMap.plugin([
				'AMap.ControlBar', 
				'AMap.ToolBar',
				'AMap.HawkEye',
				'AMap.Geolocation',
				'AMap.CitySearch',
				'AMap.CircleEditor',
				'AMap.Geocoder',
				'AMap.AutoComplete'], function () {//异步加载插件
				var controlBar = new AMap.ControlBar({//控制地图旋转插件
					position: {
						right: '10px',
						top: '10px'
					}
				});
				that.sendmap.addControl(controlBar);
				var toolBar = new AMap.ToolBar({//地图缩放插件
					position: {
						right: '40px',
						top: '110px'
					}

				});
				that.sendmap.addControl(toolBar)
				//浏览器定位
				var geolocation = new AMap.Geolocation({
					enableHighAccuracy: true, // 是否使用高精度定位，默认：true
					timeout: 10000, // 设置定位超时时间，默认：无穷大
					offset: [10, 10],  // 定位按钮的停靠位置的偏移量
					zoomToAccuracy: true,  //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
					position: 'RB' //  定位按钮的排放位置,  RB表示右下
				})
				geolocation.getCurrentPosition(function(status,result){
					if(status=='complete'){
						//定位成功
						console.log('定位成功',status,result);
					}else{
						//定位失败
						console.log('定位失败',status,result);
					}
				});
				that.sendmap.addControl(geolocation)
				if(Object.keys(that.lookData).length>0){
					// console.log('lookData',that.lookData);
					that.inform.address = that.lookData.address
					that.addressInput(that.lookData.address)
					that.addMarkerSend(that.lookData.point)
				}
			});
		},
		//添加marker
		addMarkerSend(point){
			let that = this
			that.sendmap.clearMap()
			let pointMap = new AMap.LngLat(point.lng, point.lat)
			that.send_marker = new AMap.Marker({
				map:that.sendmap,
				position: pointMap,
				draggable:true,//是否可拖拽
			});
			that.sendmap.add(that.send_marker);
			that.sendmap.panTo(pointMap)
			let address = that.getLocation(point)
			address.then((res)=>{
				// console.log(res);
				that.inform.currRadio = ''
				let dataObj = {
					'location':{
						lng:point.lng,
						lat:point.lat
					},
					'name':res
				}
				that.backLocation(dataObj)
				that.inform.address = res
				that.addressInput(res)
			})
			
			//监听拖动结束后事件
			that.send_marker?.on("dragend", function(even) {
				// console.log('even',even);
				that.sendmap.panTo(even.lnglat)
				let address = that.getLocation(even.lnglat)
				address.then((res2)=>{
					// console.log(res);
					that.inform.currRadio = ''
					let dataObj = {
						'location':{
							lng: even.lnglat.lng,
							lat: even.lnglat.lat
						},
						'name':res2
					}
					that.backLocation(dataObj)
					that.inform.address = res2
					that.addressInput(res2)
				})
			})
		},
		//输入框变化
		addressInput(val){
			console.log('val',val);
			let that = this
			if(val){
				// 实例化AutoComplete
				var autoOptions = {
					city: "全国",
				};
				var autoComplete = new AMap.AutoComplete(autoOptions);
				autoComplete.search(val, function (status, result) {
					if (status === "complete" && result.info === "OK") {
						console.log('result:',result);
						that.addressList = result.tips
						// if(result.tips[0].id){
						// 	let point = {
						// 		lng:result.tips[0].location.lng,
						// 		lat:result.tips[0].location.lat
						// 	}
						// 	that.inform.currRadio = result.tips[0].id
						// 	that.addMarkerSend(point)
						// 	that.backLocation(result.tips[0])
						// }
					}
				});
			}
		},
		//列表改变选择
		radioChange(val){
			let that = this
			for(var i in that.addressList){
				if(val == that.addressList[i].id){
					that.setMarker(that.addressList[i].location)
					that.backLocation(that.addressList[i])
				}
			}
		},
		//返回定位信息
		backLocation(data){
			let dataObj = {
				point:{
					lng:data.location.lng,
					lat:data.location.lat,
				},
				address:data.name
			}
			this.$emit('backLocation',dataObj)
		},
		//设置定位点
		setMarker(point){
			let that = this
			if(this.send_marker == null){
				this.addMarkerSend(point)
			}else{
				that.send_marker.setPosition(point)
				that.sendmap.panTo(point)
			}
			
		},
		//获取地址返回
    getLocation(point){
			let that = this
      return new Promise((resolve, reject) => {
				setTimeout(()=>{
					var geocoder = new AMap.Geocoder({
						city: "全国", //城市设为北京，默认：“全国”
						radius: 1000 //范围，默认：500
					});
					geocoder.getAddress(point, function(status, result) {
            if (status === 'complete'&&result.regeocode) {
							var address = result.regeocode.formattedAddress;
							// console.log('地址',address);
							resolve(address)
            }else{
							console.error('根据经纬度查询地址失败')
            }
        	});
					
				},0)
      });
    },

	}
	
} 

</script>
<style lang="scss">
.sendLocaMap{
	height: 100%;
	position: relative;
	.searchDiv{
		position: absolute;
		left: 10px;
		top: 10px;
		width: 220px;
		z-index: 1;
	}
	.addressDiv{
		position: absolute;
		left: 10px;
		top: 50px;
		z-index: 2;
		background-color: #fff;
		padding: 0 10px 10px;
		box-sizing: border-box;
		border-radius: 4px;
		box-shadow: 0 2px 2px 3px rgba(0,0,0,.2);
		height: 50%;
		overflow: auto;
		.el-radio{
			position: relative;
			display: block;
			margin-right: 0;
			.el-radio__input{
				position: absolute;
				right: 0px;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				&.is-checked{
					opacity: 1;
				}
			}
			.el-radio__label{
				padding: 0;
			}
		}
		.li{
			padding: 5px;
			border-bottom: 1px solid #ddd;
			color: #666;
			width: 200px;
			overflow: hidden;
			box-sizing: border-box;
			.name{
				width: 70%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.tips{
				padding-top: 5px;
				font-size: 12px;
				color: #999;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
	.tangram-suggestion-main{
		position: fixed !important;
	}
	#map1 {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
		.BMap_cpyCtrl,.anchorBL{
			display: none;
		}
		.BMap_Marker.BMap_noprint{
			z-index: 0 !important;
		}
		.maplibTc{
			z-index: 2;
		}
		.BMap_omCtrl{
			z-index: 1 !important;
		}
	}
}
</style>