<template>
  <div class="placeAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="场所名称:" prop="homeName">
            <el-input v-model="inform.homeName" placeholder="请输入场所名称"></el-input>
          </el-form-item>
          <el-form-item label="地址:" prop="position">
            <el-input type="text" readonly v-model="inform.position" placeholder="请定位场所地址">
              <template slot="append">
                <el-button @click="getLngLat()">定位</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="联系人:" prop="admin">
            <el-input v-model="inform.admin" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系号码:" prop="phone">
            <el-input v-model="inform.phone" placeholder="请输入联系手机号"></el-input>
          </el-form-item>
          <el-form-item label="场所环境:">
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.installEnvIconsShow" :key="index" v-if="inform.installEnvIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'1')"></i>
                </div>
              </div>
              <el-upload
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest1"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="安装前勘察:">
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.sceneIconsShow" :key="index" v-if="inform.sceneIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'2')"></i>
                </div>
              </div>
              <el-upload
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest2"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="安装前:">
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.installBeforeIconsShow" :key="index" v-if="inform.installBeforeIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'3')"></i>
                </div>
              </div>
              <el-upload
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest3"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="安装后:">
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.installedIconsShow" :key="index" v-if="inform.installedIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'4')"></i>
                </div>
              </div>
              <el-upload
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest4"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input type="textarea" :rows="5" v-model="inform.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>

    <!--定位组件-->
    <send-loca-map ref="sendLocaRef" @backLocation="backLocation" />

    <el-dialog title="预览" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
import { wgs84togcj02,gcj02towgs84 } from '@/utils/positionUtil';//高德坐标与gps坐标转换方法
import sendLocaMap from '@/views/components/send-location'
var vm;
export default {
  data() {
    return {

      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        homeName:'',//场所名称
        admin:'',//联系人
        phone:'',//联系号码
        position:'',//位置
        longitude:'',//经度
        latitude:'',//纬度
        remark:'',//备注
        installEnvIcons:[],//场所环境
        sceneIcons:[],//安装前环境
        installBeforeIcons:[],//安装前
        installedIcons:[],//安装后
        installEnvIconsShow:[],//场所环境
        sceneIconsShow:[],//安装前环境
        installBeforeIconsShow:[],//安装前
        installedIconsShow:[],//安装后
        num1:0,
        num2:0,
        num3:0,
        num4:0,
      },

      rules :{
        homeName: [
          { required: true, message: '请输入场所名称', trigger: 'blur' },
        ],
        phone: [
          { required: false, message: '请输入手机号', trigger: 'blur' },
          { pattern :/^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/, message:'请输入11位正确的手机号'}
        ],
        position: [
          { required: true, message: '请定位场所位置', trigger: 'blur' }
        ],
      },

      //查看上传的图片
      dialogVisible:false,
      dialogImageUrl:'',
    }
  },
  components:{
    sendLocaMap
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true

      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '新增场所'
        }else if(type == 'edit'){
          vm.dioTit = '编辑场所'
          let wgs84togcj02Val = wgs84togcj02(item.longitude,item.latitude);
          let arr1 = [],arr2 = [],arr3 = [],arr4 = [];
          if(item.installEnvIcon&&item.installEnvIcon!=''){
            let arr = item.installEnvIcon.split(',')
            for(var i in arr){
              let obj = {
                url:`${globalStr}/fileView${arr[i]}`
              }
              arr1.push(obj)
            }
          }
          if(item.sceneIcon&&item.sceneIcon!=''){
            let arr = item.sceneIcon.split(',')
            for(var i in arr){
              let obj = {
                url:`${globalStr}/fileView${arr[i]}`
              }
              arr2.push(obj)
            }
          }
          if(item.installBeforeIcon&&item.installBeforeIcon!=''){
            let arr = item.installBeforeIcon.split(',')
            for(var i in arr){
              let obj = {
                url:`${globalStr}/fileView${arr[i]}`
              }
              arr3.push(obj)
            }
          }
          if(item.installedIcon&&item.installedIcon!=''){
            let arr = item.installedIcon.split(',')
            for(var i in arr){
              let obj = {
                url:`${globalStr}/fileView${arr[i]}`
              }
              arr4.push(obj)
            }
          }
          vm.inform = {
            id:item.id,
            homeName:item.homeName,//场所名称
            admin:item.admin,//联系人
            phone:item.phone,//联系号码
            position:item.position,//位置
            longitude:wgs84togcj02Val[0],//经度
            latitude:wgs84togcj02Val[1],//纬度
            remark:item.remark,//备注
            installEnvIcons:[],//场所环境
            sceneIcons:[],//安装前环境
            installBeforeIcons:[],//安装前
            installedIcons:[],//安装后
            installEnvIconsShow:arr1,//场所环境
            sceneIconsShow:arr2,//安装前环境
            installBeforeIconsShow:arr3,//安装前
            installedIconsShow:arr4,//安装后
            num1:arr1.length,//场所环境
            num2:arr2.length,//安装前环境
            num3:arr3.length,//安装前
            num4:arr4.length,//安装后
          }
        }
      })
    },
    //返回定位数据
    backLocation(val){
      //返回的经纬度是wgs84坐标
      this.inform.position = val.address
      this.inform.longitude = val.lng
      this.inform.latitude = val.lat
    },
    //打开定位弹窗
    getLngLat (){
      if(this.inform.position!=''){
        let gcj02towgs84Val = gcj02towgs84(this.inform.longitude,this.inform.latitude)
        let dataObj = {
          lng:gcj02towgs84Val?gcj02towgs84Val[0]:'',
          lat:gcj02towgs84Val?gcj02towgs84Val[1]:'',
          address:this.inform.position
        }
        this.$refs.sendLocaRef.init('send',dataObj)
      }else{
        this.$refs.sendLocaRef.init('send')
      }
    },
    //移除文件
    delIcon(index,item,type) {
      if(!item.uid){
        if(type=='1'){
          this.inform.installEnvIconsShow.splice(index, 1);
        }else if(type=='2'){
          this.inform.sceneIconsShow.splice(index, 1);
        }else if(type=='3'){
          this.inform.installBeforeIconsShow.splice(index, 1);
        }else if(type=='4'){
          this.inform.installedIconsShow.splice(index, 1);
        }
      }else{
        if(type=='1'){
          for(var x=0;x<this.inform.installEnvIcons.length;x++){
            if(this.inform.installEnvIcons[x].uid === this.inform.installEnvIconsShow[index].uid){
              this.inform.installEnvIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.installEnvIconsShow.splice(index, 1);
        }else if(type=='2'){
          for(var x=0; x<this.inform.sceneIcons.length;x++){
            if(this.inform.sceneIcons[x].uid === this.inform.sceneIconsShow[index].uid){
              this.inform.sceneIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.sceneIconsShow.splice(index, 1);
        }else if(type=='3'){
          for(var x=0; x<this.inform.installBeforeIcons.length;x++){
            if(this.inform.installBeforeIcons[x].uid === this.inform.installBeforeIconsShow[index].uid){
              this.inform.installBeforeIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.installBeforeIconsShow.splice(index, 1);
        }else if(type=='4'){
          for(var x=0; x<this.inform.installedIcons.length;x++){
            if(this.inform.installedIcons[x].uid === this.inform.installedIconsShow[index].uid){
              this.inform.installedIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.installedIconsShow.splice(index, 1);
        }
      }
    },
    //预览图片
    iconPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    //上传格式校验
    beforeUpload (file){
      const isPNG = file.type ==='image/png' || file.type === 'image/jpg'||file.type === 'image/jpeg'
      const isLt500M = file.size / 1024 / 1024 < 500
      if (!isPNG) {
        vm.$message.warning('上传图片只能是JPG、JPEG或PNG 格式!')
      }
      if (!isLt500M) {
        vm.$message.warning('上传图片大小不能超过 500MB!')
      }
      return isPNG && isLt500M
      
    },
    // 文件提交
    async httpRequest1 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.installEnvIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.installEnvIconsShow.push(obj)
      }
    },
    // 文件提交
    async httpRequest2 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.sceneIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.sceneIconsShow.push(obj)
      }
    },
    // 文件提交
    async httpRequest3 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.installBeforeIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.installBeforeIconsShow.push(obj)
      }
    },
    // 文件提交
    async httpRequest4 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.installedIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.installedIconsShow.push(obj)
      }
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.createHome()
          }else if(vm.dioType=='edit'){
            vm.modifyHome()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        // vm.$refs[formEl].resetFields()
      // })
      vm.inform ={
        id:'',
        homeName:'',//场所名称
        admin:'',//联系人
        phone:'',//联系号码
        position:'',//位置
        longitude:'',//经度
        latitude:'',//纬度
        remark:'',//备注
        installEnvIcons:[],//场所环境
        sceneIcons:[],//安装前环境
        installBeforeIcons:[],//安装前
        installedIcons:[],//安装后
        installEnvIconsShow:[],//场所环境
        sceneIconsShow:[],//安装前环境
        installBeforeIconsShow:[],//安装前
        installedIconsShow:[],//安装后
        num1:0,
        num2:0,
        num3:0,
        num4:0,
      }
      vm.isDio = false
    },
    //新增
    async createHome(){
      if(vm.$store.state.csType=='') return vm.$message.warning('请创建或选择区域！')
      let gcj02towgs84Val = gcj02towgs84(vm.inform.longitude,vm.inform.latitude)
      let dataObj = new FormData();
      dataObj.append('areaId', vm.$store.state.csType)//区域ID
      dataObj.append('homeName', vm.inform.homeName)//场所名称
      dataObj.append('position', vm.inform.position)//位置
      dataObj.append('longitude', gcj02towgs84Val[0])//经度
      dataObj.append('latitude', gcj02towgs84Val[1])//纬度
      dataObj.append('admin', vm.inform.admin)//联系人
      dataObj.append('phone', vm.inform.phone)//联系号码
      dataObj.append('remark', vm.inform.remark)//备注
      if(vm.inform.installEnvIcons.length>0){//场所环境
        for(var i in vm.inform.installEnvIcons){
          dataObj.append('installEnvIcons['+i+'].file',vm.inform.installEnvIcons[i])
        }
      }
      if(vm.inform.sceneIcons.length>0){//安装前环境
        for(var i in vm.inform.sceneIcons){
          dataObj.append('sceneIcons['+i+'].file',vm.inform.sceneIcons[i])
        }
      }
      if(vm.inform.installBeforeIcons.length>0){//安装前
        for(var i in vm.inform.installBeforeIcons){
          dataObj.append('installBeforeIcons['+i+'].file',vm.inform.installBeforeIcons[i])
        }
      }
      if(vm.inform.installedIcons.length>0){//安装后
        for(var i in vm.inform.installedIcons){
          dataObj.append('installedIcons['+i+'].file',vm.inform.installedIcons[i])
        }
      }
      const res = await apiUrl.createHome(dataObj,100000)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async modifyHome(){
      if(vm.$store.state.csType=='') return vm.$message.warning('请创建或选择区域！')
      let gcj02towgs84Val = gcj02towgs84(vm.inform.longitude,vm.inform.latitude)
      let dataObj = new FormData();
      dataObj.append('id', vm.inform.id)//场所ID
      dataObj.append('areaId', vm.$store.state.csType)//区域ID
      dataObj.append('homeName', vm.inform.homeName)//场所名称
      dataObj.append('position', vm.inform.position)//位置
      dataObj.append('longitude', gcj02towgs84Val[0])//经度
      dataObj.append('latitude', gcj02towgs84Val[1])//纬度
      dataObj.append('admin', vm.inform.admin)//联系人
      dataObj.append('phone', vm.inform.phone)//联系号码
      dataObj.append('remark', vm.inform.remark)//备注
      if(vm.inform.installEnvIcons.length>0){//场所环境
        for(var i in vm.inform.installEnvIcons){
          dataObj.append('installEnvIcons['+i+'].file',vm.inform.installEnvIcons[i])
        }
      }
      if(vm.inform.sceneIcons.length>0){//安装前环境
        for(var i in vm.inform.sceneIcons){
          dataObj.append('sceneIcons['+i+'].file',vm.inform.sceneIcons[i])
        }
      }
      if(vm.inform.installBeforeIcons.length>0){//安装前
        for(var i in vm.inform.installBeforeIcons){
          dataObj.append('installBeforeIcons['+i+'].file',vm.inform.installBeforeIcons[i])
        }
      }
      if(vm.inform.installedIcons.length>0){//安装后
        for(var i in vm.inform.installedIcons){
          dataObj.append('installedIcons['+i+'].file',vm.inform.installedIcons[i])
        }
      }
      if(vm.inform.installEnvIconsShow.length>0){//改变后场所环境
        // 首先使用filter过滤出没有uid的元素  
        const fileArr = vm.inform.installEnvIconsShow.filter(item => !('uid' in item));
        // 然后使用map提取这些元素的name值  
        const arr1 = fileArr?.map(item => item.url);
        const newArr1 = vm.getStrUrl(arr1)
        if(arr1.length>0 && arr1.length!=this.inform.num1){
          dataObj.append('changeOriInstallEnvIcons',newArr1.join(','))
        }else if(arr1.length==0){
          dataObj.append('changeOriInstallEnvIcons','')
        }
      }else{
        dataObj.append('changeOriInstallEnvIcons','')
      }
      if(vm.inform.sceneIconsShow.length>0){//改变后安装前环境
        // 首先使用filter过滤出没有uid的元素  
        const fileArr = vm.inform.sceneIconsShow.filter(item => !('uid' in item));
        // 然后使用map提取这些元素的name值  
        const arr2 = fileArr?.map(item => item.url);
        const newArr2 = vm.getStrUrl(arr2)
        if(arr2.length>0 && arr2.length!=this.inform.num2){
          dataObj.append('changeOriSceneIcons',newArr2.join(','))
        }else if(arr2.length==0){
          dataObj.append('changeOriSceneIcons','')
        }
      }else{
        dataObj.append('changeOriSceneIcons','')
      }
      if(vm.inform.installBeforeIconsShow.length>0){//改变后安装前
        // 首先使用filter过滤出没有uid的元素  
        const fileArr = vm.inform.installBeforeIconsShow.filter(item => !('uid' in item));
        // 然后使用map提取这些元素的name值  
        const arr3 = fileArr?.map(item => item.url);
        const newArr3 = vm.getStrUrl(arr3)
        if(arr3.length>0 && arr3.length!=this.inform.num3){
          dataObj.append('changeOriInstallBeforeIcons',newArr3.join(','))
        }else if(arr3.length==0){
          dataObj.append('changeOriInstallBeforeIcons','')
        }
      }else{
        dataObj.append('changeOriInstallBeforeIcons','')
      }
      if(vm.inform.installedIconsShow.length>0){//改变后安装后
        // 首先使用filter过滤出没有uid的元素  
        const fileArr = vm.inform.installedIconsShow.filter(item => !('uid' in item));
        // 然后使用map提取这些元素的name值  
        const arr4 = fileArr?.map(item => item.url);
        const newArr4 = vm.getStrUrl(arr4)
        if(arr4.length>0 && arr4.length!=this.inform.num4){
          dataObj.append('changeOriInstalledIcons',newArr4.join(','))
        }else if(arr4.length==0){
          dataObj.append('changeOriInstalledIcons','')
        }
      }else{
        dataObj.append('changeOriInstalledIcons','')
      }
      const res = await apiUrl.modifyHome(dataObj,100000)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //截取字符串
    getStrUrl(arr){ 
      const newArr = arr.map(url => {  
        const index = url.indexOf('/fileView');  
        if (index !== -1) {  
          // 提取从 /fileView 后的所有字符  
          return url.slice(index + '/fileView'.length);  
        } else {  
          // 如果找不到 /fileView，则返回原始 URL 或空字符串（根据你的需求）  
          return url; // 或者返回 ''  
        }  
      });
      return newArr
    },
    
  }
}
</script>
<style lang='scss'>
.placeAddEdit{
  .contDio{
    height: 500px;
    overflow: auto;
    .iconDiv{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .imgDiv{
      display: inline-flex;
      flex-wrap: wrap;
      .item{
        margin-right: 5px;
        width: 58px;
        height: 58px;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        margin-bottom: 5px;
        .btn{
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          display: none;
          align-items: center;
          justify-content: space-around;
          i{
            font-size: 20px;
            cursor: pointer;
            color: #fff;
          }
        }
        &:hover{
          .btn{
            display: flex;
          }
        }
      }
    }
  }
  .uploader{
    .el-upload-list--picture-card{
      .el-upload-list__item{
        width: 60px;
        height: 60px;
      }
    }
    .el-upload--picture-card{
      width: 60px;
      height: 60px;
      line-height: 58px;
      i.el-icon-plus{
        line-height:inherit;
      }
    }
  }
}
</style>